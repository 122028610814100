import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import classnames from 'classnames';

export const iconTypes = [
  'actions-menu',
  'add-image',
  'advertisement',
  'alert',
  'analytics',
  'arrow-down',
  'arrow-up',
  'back',
  'badge',
  'ban',
  'billing',
  'building',
  'calendar',
  'caret-down',
  'call-bell',
  'catalog',
  'cellphone',
  'check',
  'checkout',
  'chevron-down',
  'chevron-right',
  'clock',
  'clone',
  'close',
  'copy',
  'coupons',
  'crop',
  'cross-arrows',
  'customization',
  'design',
  'desktop',
  'donation',
  'edit',
  'external-link',
  'eye-slash',
  'eye',
  'facebook',
  'filter',
  'form',
  'geolocation',
  'groups',
  'growth',
  'help',
  'home',
  'hurry',
  'image',
  'info',
  'instagram',
  'instagram-shop',
  'integrations',
  'lifebuoy',
  'link',
  'loading',
  'magic-wand',
  'map-marker',
  'marketing',
  'menu',
  'minus-sign',
  'note',
  'notification',
  'orders',
  'payment',
  'phone',
  'plus-circle',
  'plus-sign',
  'price-discount',
  'price-update',
  'print',
  'print-settings',
  'qr-code',
  'return',
  'remove-circle',
  'rocket',
  'search',
  'search-engine',
  'settings',
  'share',
  'shipping',
  'sign-out',
  'sort',
  'sort-order',
  'sound',
  'sound-disabled',
  'statistics',
  'status-update',
  'stock',
  'store',
  'subscription',
  'table',
  'tablet',
  'tags',
  'ticket',
  'trash-can',
  'twitter',
  'unlink',
  'upsell',
  'user',
  'users',
  'web',
  'whatsapp',
  'youtube',
  'zoom',
  'integrations-link',
] as const;

export const iconSizes = ['xs', 'sm', 'md', 'lg'] as const;

export const iconColors = [
  'inherit',
  'blue',
  'pink',
  'yellow',
  'cyan',
  'theme1',
  'theme1-contrast',
  'theme2',
  'theme2-contrast',
  'success',
  'danger',
  'warning',
  'info',
  'white',
  'black',
];

export type IconTypes = typeof iconTypes;
export type IconType = IconTypes[number];
export type IconSize = (typeof iconSizes)[number];
export type IconColor = (typeof iconColors)[number];

@Component({
  selector: 'pxw-icon:not([icon])',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  @Input() icon: IconType | undefined;
  @Input() size: IconSize = 'sm';
  @Input() color: IconColor = 'inherit';
  @Input() spin = false;
  @Input() disabled = false;

  @HostBinding('class')
  get classes() {
    return classnames('pxw-icon', `pxw-icon--size-${this.size}`, `pxw-icon--color-${this.color}`, {
      'pxw-icon--spin': this.spin,
      'pxw-icon--disabled': this.disabled,
    });
  }
}
